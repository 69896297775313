<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>统计报表</BreadcrumbItem>
                <BreadcrumbItem>简历数据</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem>
                    <DatePicker type="daterange" v-model="filter.resumeDateRange" placeholder="请选择时间周期" separator=" 至 "></DatePicker>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.userId" placeholder="请选择用户" filterable clearable>
                        <Option v-for="item in userArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <deptTreeSelect v-model="filter.departmentId" :options="departmentOpts" placeholder="请选择所属组织" noOptionsText="暂无数据" noResultsText="没有匹配数据" clearable />
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">搜索</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="resetting">重置</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" icon="md-cloud-download" @click="handleExport" v-if="permissionButton.export">导出</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table border stripe :columns="columns" :data="dataList" :loading="loading"></Table>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize" :total="total" :current="pageNum"
                              :page-size-opts="[15, 20, 50, 100]"
                              @on-change="changePage" @on-page-size-change="changPageSize" show-sizer show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
</template>

<script>
import permission from '@/common/permission'
import {reqGetUserList} from "../../../api/system-api";
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import deptTreeSelect from "@riophae/vue-treeselect";
import {reqGetDepartmentTreeOpts, reqGetResumeReport, reqExportResumeReport} from "../../../api/admin-api";

export default {
    name: 'resumeReport',
    components: {deptTreeSelect},
    data() {
        return {
            filter: {
                resumeDateRange: null,
                userId: null,
                departmentId: null,
            },
            userArray: [],
            departmentOpts: [],
            loading: false,
            dataList: [],
            permissionButton: {
                export: false,
            },
            total: 0,
            pages: 1,
            pageSize: 20,
            pageNum: 1,
            columns: [
                {
                    title: '用户',
                    key: 'createByName',
                    width: 200,
                },
                {
                    title: '所属组织',
                    key: 'departmentName',
                    tooltip: 'true',
                    minWidth: 150,
                },
                {
                    title: '新增简历',
                    key: 'countsOfCreated',
                    align: 'center',
                    width: 120,
                },
                {
                    title: '简历下载',
                    key: 'countsOfDownloaded',
                    align: 'center',
                    width: 120,
                },
            ],
        }
    },
    methods: {
        init() {
            reqGetUserList().then((res) => {
                this.userArray = res.data;
            }).catch(() => {
                this.userArray = [];
            });

            reqGetDepartmentTreeOpts().then(res => {
                this.departmentOpts = res.data.data;
            }).catch(()=>{
                this.departmentOpts = [];
            });
        },
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getResumeReport();
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getResumeReport();
        },
        doSearching() {
            this.pageNum = 1;
            this.getResumeReport();
        },
        getResumeReport() {
            this.loading = true;
            this.dataList = [];
            let startDate = ((this.filter.resumeDateRange && this.filter.resumeDateRange.length > 0 && this.filter.resumeDateRange[0]) ? this.$moment(this.filter.resumeDateRange[0]).format('YYYY-MM-DD') : null);
            let endDate = ((this.filter.resumeDateRange && this.filter.resumeDateRange.length > 1 && this.filter.resumeDateRange[1]) ? this.$moment(this.filter.resumeDateRange[1]).format('YYYY-MM-DD') : null);
            let params = Object.assign({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                startDate: startDate,
                endDate: endDate,
            }, this.filter);

            reqGetResumeReport(params).then((res) => {
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            });
        },
        resetting() {
            this.loading = true;
            this.dataList = [];
            this.filter.resumeDateRange= null;
            this.filter.userId= null;
            this.filter.departmentId= null;
            let startDate = ((this.filter.resumeDateRange && this.filter.resumeDateRange.length > 0 && this.filter.resumeDateRange[0]) ? this.$moment(this.filter.resumeDateRange[0]).format('YYYY-MM-DD') : null);
            let endDate = ((this.filter.resumeDateRange && this.filter.resumeDateRange.length > 1 && this.filter.resumeDateRange[1]) ? this.$moment(this.filter.resumeDateRange[1]).format('YYYY-MM-DD') : null);
            let params = Object.assign({
                pageNum: 1,
                pageSize: 20,
                startDate: startDate,
                endDate: endDate,
            }, this.filter);

            reqGetResumeReport(params).then((res) => {
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            });
        },
        handleExport() {
            this.$Modal.confirm({
                title: '导出简历数据',
                content: '确定导出简历数据查询结果？',
                width: '320',
                onOk: () => {
                    let startDate = ((this.filter.resumeDateRange && this.filter.resumeDateRange.length > 0 && this.filter.resumeDateRange[0]) ? this.$moment(this.filter.resumeDateRange[0]).format('YYYY-MM-DD') : null);
                    let endDate = ((this.filter.resumeDateRange && this.filter.resumeDateRange.length > 1 && this.filter.resumeDateRange[1]) ? this.$moment(this.filter.resumeDateRange[1]).format('YYYY-MM-DD') : null);
                    this.filter.startDate = startDate;
                    this.filter.endDate = endDate;

                    reqExportResumeReport(this.filter).then((res)=>{
                        let filename = 'Resume.xlsx';
                        const disposition = res.headers['content-disposition'];
                        if (typeof disposition !== 'undefined') {
                            let deDisposition = decodeURIComponent(disposition);
                            filename = deDisposition.substring(deDisposition.indexOf('filename=') + 9, deDisposition.length);
                        }

                        let blob = new Blob([res.data], {type: 'application/vnd.ms-excel;charset=utf-8'}) //type这里表示xlsx类型
                        let downloadElement = document.createElement('a')
                        let href = window.URL.createObjectURL(blob) //创建下载的链接
                        downloadElement.href = href
                        downloadElement.download = filename //下载后文件名
                        document.body.appendChild(downloadElement)
                        downloadElement.click() //点击下载
                        document.body.removeChild(downloadElement) //下载完成移除元素
                        window.URL.revokeObjectURL(href) //释放掉blob对象
                    }).catch((err)=>{
                        this.$Message.error('导出失败')
                    })
                },
            })
        }
    },
    mounted() {
        let permissionId = this.$route.query.permissionId;
        permission.getPermissionButton(permissionId, this.permissionButton);
        this.init();
        this.getResumeReport();
    }
}
</script>
